
import {
  endorsementMapActions,
  endorsementMapGetters,
  endorsementMapState
} from "@/store/modules/endorsements";
import { ITableWrapperColumns } from "@/types";
import Vue from "vue";
import { endorsementsTableColumns } from "./endorsementsTableColumns";
import NoResultsFound from "@/components/NotFound/NoResultsFound.vue";
import { getCompaniesForFilter } from "@/helpers/companies";

interface IEndorsementData {
  errorMessage: string;
  successMessage: string;
  tableColumns: ITableWrapperColumns;
  isAdmin: boolean;
  filters: any[];
  tableRowActions: any[];
  endorsementId: string;
  bulkMenuActions: any[];
  selectedDocuments: any;
  tableActions: any[];
  uploadUrl: string;
  loadingText: string;
}
export default Vue.extend({
  name: "Endorsements",
  components: {
    ImportsUploader: () =>
      import("@/views/admin/imports/ListImports/ImportsUploader.vue"),
    NoResultsFound
  },
  data(): IEndorsementData {
    return {
      successMessage: "",
      errorMessage: "",
      isAdmin: true,
      tableColumns: endorsementsTableColumns,
      filters: [
        {
          field: "formUsage",
          title: "Form Usage",
          options: [
            { value: "Mandatory", label: "Mandatory" },
            { value: "Optional", label: "Optional" }
          ]
        },
        {
          field: "appliesTo",
          title: "Line Of Business",
          options: [
            { value: "H", label: "Homeowner" },
            { value: "D", label: "Dwelling fire" },
            { value: "T", label: "Tenant" }
          ]
        },
        {
          field: "companies",
          title: "Company",
          options: getCompaniesForFilter()
        }
      ],
      tableRowActions: [
        { label: "Edit", value: "edit", faIcon: "edit" },
        {
          label: "Delete",
          value: "delete",
          faIcon: "trash-alt",
          class: "bg-red-400 text-white hover:text-white hover:bg-red-500"
        }
      ],
      endorsementId: "",
      bulkMenuActions: [{ title: "Delete Selected", disabled: false }],
      selectedDocuments: [],
      tableActions: ["Import Endorsements", "All Activities"].sort(),
      uploadUrl: "endorsements/bulk/upload",
      loadingText: ""
    };
  },
  methods: {
    ...endorsementMapActions([
      "getAllEndorsements",
      "deleteEndorsement",
      "deleteBulkEndorsements"
    ]),
    onToolbarItemClick(event: string) {
      switch (event) {
        case "create":
          this.$router.push(`/admin/endorsements/create`).catch(() => {});
          break;
      }
    },
    async fetchPageData(queryObject: Record<string, any>): Promise<any> {
      try {
        this.loadingText = "Loading endorsements. Please wait...";
        this.$emit("scrollTop");
        await this.getAllEndorsements({
          query: { ...queryObject }
        });
      } catch (error) {
        this.errorMessage = error.message;
        this.$bugSnagClient.notify(error);
      }
    },
    async viewCallback(dataReceived: any): Promise<any> {
      const { _id } = dataReceived;
      this.$router.push(`/admin/endorsements/${_id}/edit`).catch(() => {});
    },
    async handleResponseAction(payload: any) {
      const { data } = payload;
      switch (payload.name) {
        case "edit":
          this.viewCallback(data);
          break;
        case "delete":
          this.deleteCallback(data._id);
          break;
        default:
          this.errorMessage = "Operation specified is not supported";
      }
    },
    async deleteCallback(id: any): Promise<void> {
      try {
        this.endorsementId = id;
        this.$modal.show("endorsementDeleteModal");
      } catch (error) {
        if (error !== "cancel") {
          console.error(error.message);
        }
        this.$bugSnagClient.notify(error);
      }
    },
    async deleteOneEndorsement(): Promise<void> {
      try {
        this.loadingText = "Deleting endorsement. Please wait...";
        await this.deleteEndorsement(this.endorsementId);
        this.$modal.hide("endorsementDeleteModal");
        this.successMessage = "Endorsement deleted successfully";
      } catch (error) {
        this.errorMessage = error.message;
        this.$bugSnagClient.notify(error);
      }
    },
    async bulkActionHandler(action: string) {
      switch (action) {
        case "Delete Selected": {
          try {
            this.loadingText = "Deleting endorsements. Please wait...";
            const endorsementsToBeDeletedIds = this.selectedDocuments.map(
              (document: any) => document._id
            );
            await this.deleteBulkEndorsements(endorsementsToBeDeletedIds);
            this.$appNotifySuccess("Selected endorsements deleted");
            this.fetchPageData(
              this.$store.state.list_view_module["filterQuery"] || {}
            );
          } catch (error) {
            this.errorMessage = error.message;
            this.$bugSnagClient.notify(error);
          }
          break;
        }
        default:
          break;
      }
    },
    tableActionHandler(action: string) {
      if (action === "Import Endorsements") {
        this.$modal.show("uploadEndorsementsImportFileModal");
      } else if (action === "All Activities") {
        this.$router.push(`/admin/endorsements/activities`).catch(() => {});
      }
    }
  },
  computed: {
    ...endorsementMapGetters(["getTotalRecords", "getEndorsementLinks"]),
    ...endorsementMapState(["makingApiRequest", "allEndorsements", "links"])
  }
});
