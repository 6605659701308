var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('PageWrapDefault',{attrs:{"errorMessage":_vm.errorMessage,"successMessage":_vm.successMessage,"topActionButtonPrimary":{
    text: 'Create New Endorsement',
    key: 'create'
  }},on:{"toolbarSelectItem":_vm.onToolbarItemClick}},[_c('ListView',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.makingApiRequest),expression:"makingApiRequest"}],attrs:{"filters":_vm.filters,"hideAllFilters":false,"addAdvancedFilter":true,"element-loading-text":_vm.loadingText,"data":_vm.allEndorsements,"tableColumns":_vm.tableColumns,"hideToggleIcons":true,"totalRecords":_vm.getTotalRecords,"paginationLinks":_vm.getEndorsementLinks,"tableRowActions":_vm.tableRowActions,"showSelectable":true,"bulkMenuActions":_vm.bulkMenuActions,"tableActions":_vm.tableActions},on:{"fetch-query":_vm.fetchPageData,"tableRowClicked":_vm.viewCallback,"table-row-action":_vm.handleResponseAction,"bulk-action-event":_vm.bulkActionHandler,"selection-change":function($event){_vm.selectedDocuments = $event},"table-action":_vm.tableActionHandler}},[_c('NoResultsFound',{attrs:{"slot":"notFound","message":"No Endorsements Found."},slot:"notFound"})],1),_c('ModalMessage',{attrs:{"name":"endorsementDeleteModal","icon":['fal', 'exclamation-circle'],"iconClass":"text-red-600 text-6xl","title":"","description":"This will delete the endorsement. Continue?","showClose":true,"buttons":[
      {
        text: `Okay`,
        classList: [''],
        click: () => _vm.deleteOneEndorsement()
      },
      {
        text: `Cancel`,
        classList: ['bg-red-700'],
        click: () => _vm.$modal.hide('endorsementDeleteModal')
      }
    ]}}),_c('ModalBase',{attrs:{"name":"uploadEndorsementsImportFileModal","size":"medium","clickToClose":false,"showClose":true,"title":"Upload Import File","loading":_vm.makingApiRequest,"position":"center"}},[_c('ImportsUploader',{attrs:{"defaultType":"endorsements","showHeading":false,"url":_vm.uploadUrl},on:{"close":function($event){return _vm.$modal.hide('uploadEndorsementsImportFileModal')},"upload-success":_vm.fetchPageData}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }